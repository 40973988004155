import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AssessmentState {
    questions: object[];
    pagination: object;
    assessment: { [key: string]: { [subKey: string]: any } };
    assessmentsListing: object[],
    assessmentsListingPagination: object,
    filters: any; // Define the type of your filters here
    config: object
}

const initialState: AssessmentState = {
    questions: [],
    pagination: {},
    assessment: {},
    assessmentsListing: [],
    assessmentsListingPagination: {},
    filters: { subjects: [], grades: [], statuses: [] },
    config: {}
};

const AssessmentsSlice = createSlice({
    name: 'assessments',
    initialState,
    reducers: {
        setAssessments(state, action: PayloadAction<any>) {
            const { items, pagination } = action.payload;
            console.log(items, pagination);

            state.questions = items;
            state.pagination = pagination;
            state.filters = action.payload.filters; // Update the filters state
        },
        setConfig(state, action: PayloadAction<any>){
            state.config = action.payload;
        },
        cleanConfig(state){
            state.config = {};
        }
    },
});

export const { setAssessments, setConfig, cleanConfig } = AssessmentsSlice.actions;
export default AssessmentsSlice.reducer;
