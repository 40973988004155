import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  fetchAssessmentRequest,
  fetchAssessmentSuccess,
  fetchAssessmentFailure,
  submitAssessmentSuccess,
  submitAssessmentFailure,
  fetchReportsRequest,
  fetchReportsSuccess,
  fetchReportsFailure, submitAssessmentRequest,
} from '../../actions';
import {toast} from "react-toastify";

interface IPreview {
  assessment: Array<any>;
  reportsData: Array<any>;
  allAnswers?: Array<{
    subQuestionId: number;
    selectedAnswer?: any;
    valid_response?: { value: { selectedAnswer: string | null | Array<any> | boolean; } }; // Add valid_response field here
  }>;
  isLoading?: boolean;
  time: string | number
  isTimerActive?: boolean;
}

const initialState: IPreview = {
  assessment: [],
  reportsData: [],
  isLoading: false,
  time: null,
  isTimerActive: false // Add timer state
};

const PreviewSlice = createSlice({
  name: 'preview',
  initialState,
  reducers: {
    setTimer: (state, action) => {
      state.time = action.payload;
    },
    startTimer: (state) => {
      state.isTimerActive = true;
    },
    pauseTimer: (state) => {
      state.isTimerActive = false;
    },
    getAssessmentIsloading: (state: any, action) => {
      state.isLoading = action.payload;
    },
    setAssessment: (state, action) => {
      state.assessment = action.payload;
    },
    cleanAssessment: (state) => {
      state.assessment = [];
    },
    cleanAllAnswers: (state) => {
      state.allAnswers = [];
      state.isTimerActive = false;
    },
    cleanReports: (state) => {
      state.reportsData = [];
    },
    setMultipleSubQuestionAnswer: (state, action) => {
      const { subQuestionId, selectedAnswer } = action.payload;
      console.log('from preview slice', subQuestionId, selectedAnswer);

      if (!state.allAnswers) {
        state.allAnswers = [];
      }

      const existingAnswerIndex = state.allAnswers.findIndex(
          (answer) => answer.subQuestionId === subQuestionId
      );

      if (existingAnswerIndex !== -1) {
        //state.allAnswers[existingAnswerIndex].selectedAnswer = selectedAnswer;
      } else {
        state.allAnswers.push({ subQuestionId, valid_response: { value: selectedAnswer } });
      }
    },
    setNewValueForMathAnswers: (state, action) => {
      let {newlyAddedAnswer, subQuestionId} = action.payload;
      console.log("action payload =", action.payload)
      const existingAnswer = state.allAnswers.find(
        (answer) => answer.subQuestionId === subQuestionId
      );

      if (existingAnswer) {
        // Modify the draft directly, don't return a new object
        state.allAnswers = state.allAnswers.map(ans => {
          return ans?.subQuestionId == existingAnswer.subQuestionId ?
          {
            ...ans,
            // @ts-ignore
            valid_response: ans.valid_response.filter(v => v?.id != newlyAddedAnswer.id).concat({...newlyAddedAnswer}),
              // value: ans.valid_response.value.filter(v => v?.id != newlyAddedAnswer.id).concat({...newlyAddedAnswer})
          }
          : ans
        })
      } else {
        // Modify the draft directly, don't return a new object
        // @ts-ignore
        // state.allAnswers.push({ subQuestionId, valid_response : { value : [newlyAddedAnswer]} });
        state.allAnswers.push({ subQuestionId, valid_response : [newlyAddedAnswer] });
      }
    },
    setSubQuestionAnswer: (state, action) => { // Renamed the second reducer
      const { subQuestionId, selectedAnswer } = action.payload;
      console.log('PreviewSlice.ts selectedAnswer =', selectedAnswer)

      if (!state.allAnswers) {
        state.allAnswers = [];
      }
      const existingAnswerIndex = state.allAnswers.findIndex(
        (answer) => answer.subQuestionId === subQuestionId
      );

      if (existingAnswerIndex !== -1) {
        // Modify the draft directly, don't return a new object
        state.allAnswers[existingAnswerIndex] = {
          ...state.allAnswers[existingAnswerIndex],
            valid_response : {
                value : selectedAnswer
            }
        }
      } else {
        // Modify the draft directly, don't return a new object
        state.allAnswers.push({ subQuestionId, valid_response : { value : selectedAnswer} });
      }
    },
    setSubQuestionAnswerOld: (state, action) => {
      const { subQuestionId, selectedAnswer } = action.payload;
      console.log('from preview slice', subQuestionId, selectedAnswer);

      if (!state.allAnswers) {
        state.allAnswers = [];
      }

      const existingAnswerIndex = state.allAnswers.findIndex(
          (answer) => answer.subQuestionId === subQuestionId
      );

      if (existingAnswerIndex !== -1) {
        // Modify the draft directly, don't return a new object
        state.allAnswers[existingAnswerIndex].selectedAnswer = selectedAnswer;
      } else {
        // Modify the draft directly, don't return a new object
        state.allAnswers.push({ subQuestionId, selectedAnswer });
      }
    },
  },
  extraReducers: (builder) => {
    builder
        .addCase(fetchAssessmentRequest, (state) => {
          // Handle the request action, update the state accordingly
          state.isLoading = true;
        })
        .addCase(fetchAssessmentSuccess, (state, action: PayloadAction<any>) => {
          // Handle the success action, update the state accordingly
          state.isLoading = false;
          state.assessment = action.payload;
        })
        .addCase(fetchAssessmentFailure, (state) => {
          // Handle the failure action, update the state accordingly
          state.isLoading = false;
        })
        .addCase(submitAssessmentRequest, (state) => {
          // Handle the failure action, update the state accordingly
          state.isLoading = true;
        })
        .addCase(submitAssessmentSuccess, (state) => {
          // Handle the failure action, update the state accordingly
          state.isLoading = false;
        })
        .addCase(submitAssessmentFailure, (state) => {
          // Handle the failure action, update the state accordingly
          state.isLoading = false;
        })
        //reports
        .addCase(fetchReportsRequest, (state) => {
        // Handle the failure action, update the state accordingly
        state.isLoading = true;
        })
        .addCase(fetchReportsSuccess, (state, action: PayloadAction<any>) => {
          // Handle the failure action, update the state accordingly
          state.reportsData = action.payload;
          state.isLoading = false;
        })
        .addCase(fetchReportsFailure, (state) => {
          // Handle the failure action, update the state accordingly
          state.isLoading = false;
          state.reportsData = [];
        });
  },
});

export const {
  setTimer,
  startTimer,
  pauseTimer,
  getAssessmentIsloading,
  setAssessment,
  cleanAssessment,
  setSubQuestionAnswer,
  setSubQuestionAnswerOld,
  setNewValueForMathAnswers,
  setMultipleSubQuestionAnswer,
  cleanAllAnswers,
  cleanReports
} = PreviewSlice.actions;
export default PreviewSlice.reducer;
