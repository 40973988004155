import {AnyAction, combineReducers, Reducer} from 'redux';
import storage from 'redux-persist/lib/storage';
// @ts-ignore
import {RootState} from '../store';
// @ts-ignore
import PreviewSlice from "../slices/PreviewSlice";
import AssessmentsSlice from "../slices/AssessmentsSlice";
import QuestionSlice from "../slices/QuestionSlice";

const appReducer = combineReducers({
  preview: PreviewSlice,
  create: AssessmentsSlice,
  createQuestion: QuestionSlice,
});
const rootReducer: Reducer = (state: RootState, action: AnyAction) => {
  if (action.type === 'USER_LOGOUT') {
    storage.removeItem('persist:root');
    state = {} as RootState;
  }
  // @ts-ignore
  return appReducer(state, action);
};
export { rootReducer };
