import { PayloadAction, createSlice } from '@reduxjs/toolkit';

interface IQuestion {
  name: string;
  description: string;
  grade_id: string;
  subject_id: string;
  score_id: string;
  difficult_id: string;
  notes: string;
  source: string;
  acknowledge: string;
  layout: {};
  tags: object[];
  status: boolean | number | string;
  access: boolean | number | string;
  sub_questions: object[];

  subQuestions: object;
}

const initialState: IQuestion = {
  name: '',
  description: '',
  grade_id: '',
  subject_id: '',
  score_id: '',
  difficult_id: '',
  notes: '',
  source: '',
  acknowledge: '',
  layout: null,
  tags: [],
  status: false,
  access: false,
  sub_questions: [],
  subQuestions: {},
};

const QuestionSlice = createSlice({
  name: 'question',
  initialState,
  reducers: {
    setVal: (state, action) => {
      const { key, val } = action.payload;
      state[key] = val;
    },
    removeQuestionType: (state, action) => {
      const { type } = action.payload;
      // Create a new state object without the specified type

      if (type) {
        // Remove type from subQuestions
        const subQuestionsCopy = { ...state.subQuestions };
        Object.keys(subQuestionsCopy).forEach((key) => {
          if (subQuestionsCopy[key].type === type) {
            delete subQuestionsCopy[key];
          }
        });

        // Remove type from sub_questions
        const subQuestionsListCopy = state.sub_questions.filter(
          // @ts-ignore
          (item) => item.type !== type
        );

        return {
          ...state,
          subQuestions: subQuestionsCopy,
          sub_questions: subQuestionsListCopy,
        };
      }
    },
    resetData: () => {
      return initialState;
    },
    resetSubQuestions: (state, action) => {
      return {...state, subQuestions: {}, sub_questions: []}
    },
    setKeyValue: (state, action) => {
      const { key, subKey, value } = action.payload;

      // Check if the 'subQuestions' object exists in the state
      if (!state.subQuestions) {
        state.subQuestions = initialState.subQuestions;
      }
      // Check if the 'key' exists in 'subQuestions'
      if (!state.subQuestions[key]) {
        // If 'key' does not exist, create a new object with the 'key' and 'subKey'
        state.subQuestions[key] = { [subKey]: value };
      }
      if (
        key === 'chemistry' ||
        key === 'clozechemistry' ||
        key === 'ClozeMath' ||
        key === 'ClozeMathWImage'
      ) {
        state.subQuestions[key] = value;
      }
      // Add type "cwdad" when the key is "clozeDragDrop"
      if (key === 'cwdad') {
        state.subQuestions[key].type = 'cwdad';
        state.subQuestions[key][subKey] = value;
      }

      // Add type "cwdad" when the key is "clozeDropDown"
      if (key === 'clozeDropDown') {
        state.subQuestions[key].type = 'cwdd';
        state.subQuestions[key][subKey] = value;
        if (subKey === 'options' || subKey === 'correct_answer') {
          state.subQuestions[key] = {
            ...state.subQuestions[key],
            [subKey]: JSON.parse(value),
          };
        }
      }
      // Add type "cwdad" when the key is "clozeWithText"
      if (key === 'clozeWithText') {
        state.subQuestions[key].type = 'cwt';
        state.subQuestions[key][subKey] = value;
      }
      if (key === 'drawing') {
        state.subQuestions[key].type = 'draw';
        if (['source', 'width', 'height', 'alt'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {};
          }
          state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
          };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'liwdd') {
        // console.log('...................', key, subKey, value);
        state.subQuestions[key].type = 'liwdd';

        if (['textHover', 'width', 'alt', 'source'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else if (['duplicate'].includes(subKey)) {
          if (!state.subQuestions[key].settings) {
            state.subQuestions[key].settings = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].settings = {
              ...state.subQuestions[key].settings,
              [subKey]: value,
            }
          }
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'liwt') {
        state.subQuestions[key].type = 'liwt';
        if (['textHover', 'width', 'alt', 'source'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {
              [subKey]: value,
            };
          } else {
            state.subQuestions[key].image = {
              ...state.subQuestions[key].image,
              [subKey]: value,
            };
          }
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'shading') {
        if (!state.subQuestions[key].type)
          state.subQuestions[key].type = 'shad';
        if (
          [
            'row_count',
            'column_count',
            'cell_width',
            'cell_height',
            'shaded',
          ].includes(subKey)
        ) {
          if (!state.subQuestions[key].options) {
            state.subQuestions[key].options = {};
          }
          state.subQuestions[key].options = {
            ...state.subQuestions[key].options,
            [subKey]: value,
          };
        } else if (['source', 'title', 'alt'].includes(subKey)) {
          if (!state.subQuestions[key].image) {
            state.subQuestions[key].image = {};
          }
          state.subQuestions[key].image = {
            ...state.subQuestions[key].image,
            [subKey]: value,
          };
        } else if (['border'].includes(subKey)) {
          if (!state.subQuestions[key].canvas_container_style) {
            state.subQuestions[key].canvas_container_style = {};
          }
          state.subQuestions[key].canvas_container_style = {
            ...state.subQuestions[key].canvas_container_style,
            [subKey]: value,
          };
        } else if (['correct_answer'].includes(subKey)) {
          if (!state.subQuestions[key].correct_answer) {
            state.subQuestions[key].correct_answer = {};
          }
          state.subQuestions[key].correct_answer = {
            ...state.subQuestions[key].correct_answer,
            ...value,
          };
        } else {
          state.subQuestions[key][subKey] = value;
        }
      }
      if (key === 'tokenHighlight') {
        state.subQuestions[key][subKey] = value;
      }

      if (
        [
          'mcs',
          'mcmr',
          'taf',
          'mcbl',
          'cms',
          'cmi',
          'cml',
          'machli',
          'class',
          'ordlis',
          'sortlis',
          'math',
          'clozmat',
          'clozmwIm',
          'liwm',
          'mewrt',
          'mqg',
          'ewrt',
          'st',
          'ar',
          'ewpt',
        ].includes(key)
      ) {
        state.subQuestions = { ...state.subQuestions, [key]: value };
      }
      if (key === 'fileup') { // Others
        //File Upload Section
        state.subQuestions[key].type = 'fileup';
        if (subKey === 'max_score' || subKey === 'min_score_if_attempted') {
          state.subQuestions[key]["metdata"]["validation"][subKey] = value;
        } else if (subKey === 'distractor_rationale' || subKey === 'rubric_reference' || subKey === 'sample_answer'  || subKey === 'acknowledgements') {
          state.subQuestions[key]["more_options"][subKey] = value;
        } else if(subKey === "photo_capture" || subKey === "max_file_upload"){
          state.subQuestions[key]["settings"][subKey] = value;
        }else{
            state.subQuestions[key][subKey] = value;
        }

      }
    },
    setQuestionValues: (state, action) => {
      return action.payload;
    },
    setSubQuestionAmal: (state, { payload }: PayloadAction<any>) => {
      state.sub_questions = [payload];
    },
    saveCopySubQuestion: (state, action) => {
      const { type } = action.payload;
      let index = state?.sub_questions?.findIndex((object) => {
        // @ts-ignore
        return object?.type === type;
      });

      console.log(type, " Type in sub question");
      // Create a new object with filtered subQuestions
      const filteredSubQuestions = Object.fromEntries(
        Object.entries(state?.subQuestions).filter(([key, value]) => {
          return value.type === type;
        })
      );

      // Check if there are any values
      if (Object.keys(filteredSubQuestions).length > 0) {
        // Get the first (and only) value
        const values = Object.values(filteredSubQuestions)[0];

        if (index === -1) {
          // @ts-ignore
          state?.sub_questions?.push(values);
        } else {
          state.sub_questions[index] = values;
        }
      } else {
        // Handle the case where there are no matching subQuestions
        console.log('No matching subQuestions found');
      }
    },
  },
});

export const {
  setVal,
  resetData,
  resetSubQuestions,
  setKeyValue,
  setQuestionValues,
  removeQuestionType,
  saveCopySubQuestion,
  setSubQuestionAmal,
} = QuestionSlice.actions;

export default QuestionSlice.reducer;
