import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const AssessmentsPreview = lazy(() => import('../pages/AssessmentsPreview'));
const CurrentPreview = lazy(() => import('../pages/CurrentPreview'));
const PlayerPreview = lazy(() => import('../pages/PlayerPreview'));
const Reports = lazy(() => import('../pages/Reports'));
const StudentReports = lazy(() => import('../pages/StudentReports'));
const TeacherReports = lazy(() => import('../pages/TeacherReports'));
const Assessments = lazy(() => import('../pages/Assessments'));
const AddQuestion = lazy(() => import('../pages/AddQuestion'));

const AppRoutes = () => {
  return (
    <Routes>
      <Route path={'/views/:id'} element={<AssessmentsPreview />} />
      <Route path={'/current-preview'} element={<CurrentPreview />} />
      <Route path={'/player/:id'} element={<PlayerPreview />} />
      <Route path={'/create-assessments'} element={<Assessments />} />
        <Route path="/add-question" element={ <AddQuestion />}/>
      {/*<Route
        path="/player"
        element={<Navigate to="/player/9c24563c-ea49-48c6-a67c-36aeb1b6ed16" />}
      />*/}
      <Route path="/reports/:id" element={<Reports />} />
      <Route path="/student-reports/:id" element={<StudentReports />} />
      <Route path="/teacher-reports/:id" element={<TeacherReports />} />
    </Routes>
  );
};

export default AppRoutes;
